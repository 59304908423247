import React from 'react'
import logo from './img/GSA-logo.png'
import en from './img/en.svg'
import th from './img/th.svg'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

interface Props {
    params: { lng: string, isGuestService: boolean },
}


const Navbar = (props: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();

    const changeLanguage = (lng: string) => {
        i18n.changeLanguage(lng);
    };

    return (
        <>
            <div className="navbar bg-white fixed-top border-bottom" style={{ height: '80px', width: '100%' }}>
                <div className="d-flex align-items-center justify-content-start mx-3 mx-md-4 mx-lg-5">
                    <div>
                        <a className="" href="#" onClick={() => navigate('/')}>
                            <img src={logo} width={56} height={56} alt="Soraso Logo" />
                        </a>
                    </div>
                    {props.params.isGuestService ? (
                        <>
                            <ul className="nav flex-column p-0 font-weight-medium  md-0 border-0 ms-auto me-0">
                                <li className="nav-item"><a href="#Guest" className="text-lg ps-lg-5 ps-3 m-0" style={{ textDecoration: 'none', color: 'black', fontWeight: '500' }}>{t('guest_services')}</a></li>
                            </ul>
                        </>
                    ) : (
                        <>
                        </>
                    )}
                </div>
                <div className="row d-flex align-items-center justify-content-end me-lg-5 me-md-4 me-sm-3 me-1">
                    <div className="col p-0">
                        <div className="dropdown">
                            <button
                                className="btn dropdown-hover border-0 bg-transparent d-flex align-items-center"
                                type="button"
                                id="dropdownMenuButton"
                                data-mdb-toggle="dropdown"
                                aria-expanded="false"
                            >
                                <img src={i18n.language == 'en' ? en : th} width="30" height="30" alt="Soraso Logo" />
                                <span className="text-lg ps-2 d-none d-sm-block" style={{ fontWeight: '500' }}>{t(i18n.language == 'en' ? 'EN' : i18n.language)}</span>
                            </button>
                            <ul className="dropdown-menu border-0" aria-labelledby="dropdownMenuButton">
                                <li><a className="dropdown-item" onClick={() => changeLanguage('en')}>{t('en')}</a></li>
                                <li><a className="dropdown-item" onClick={() => changeLanguage('th')}>{t('th')}</a></li>
                            </ul>
                        </div>
                    </div>
                </div>

            </div>
        </>

    )
}

export default Navbar