import '../i18n';
import React from 'react';
import LandingPage from './LandingPage';
import { useTranslation } from 'react-i18next';
import { Route, Routes } from 'react-router-dom';
import TermCondition from './TermCondition';
import PrivacyPolicy from './PrivacyPolicy';

const Home: React.FC = () => {
    const { t, i18n } = useTranslation();
    return (
        <>
            <Routes>
                <Route path='/' element={<LandingPage />} />
                <Route path='/terms' element={<TermCondition />} />
                <Route path='/privacy-policy' element={<PrivacyPolicy />} />
            </Routes>
        </>
    );
};

export default Home;
