import React from 'react'
import '../component/css/styles.css';
import Banner from '../component/img/GSA_splash_screen.png'
import { useTranslation } from 'react-i18next';
import pic1 from '../component/img/Frame 1000001453.jpg'
import pic2 from '../component/img/Frame 1000001454.jpg'
import pic3 from '../component/img/Frame 1000001455.jpg'
import pic4 from '../component/img/Frame 1000001456.jpg'
import pic5 from '../component/img/Frame 1000001457.jpg'
import pic6 from '../component/img/Frame 1000001458.jpg'
import pic7 from '../component/img/Frame 1000001459.jpg'
import pic8 from '../component/img/Frame 1000001460.jpg'
import Navbar from '../component/Navbar';
import Footer from '../component/Footer';

type Props = {}

const data = [
  { header: "Pre Check-in", detail: "detail_pre_check_in", pictureUrl: pic1 },
  { header: "Get Room Key", detail: "detail_get_room_key", pictureUrl: pic3 },
  { header: "Guest Service", detail: "detail_get_service", pictureUrl: pic2 },
  { header: "Pre order Breakfast", detail: "detail_pre_order_breakfast", pictureUrl: pic4 },
  { header: "Request Service", detail: "detail_request_service", pictureUrl: pic5 },
  { header: "Do not distrub", detail: "detail_do_not_distrub", pictureUrl: pic6 },
  { header: "Clean up room", detail: "detail_make_up_room", pictureUrl: pic7 },
  { header: "Calling", detail: "detail_calling", pictureUrl: pic8 },
  // { header: "Chat", detail: "detail_chat", pictureUrl: pic8 },
  // { header: "Request Check-out", detail: "detail_request_check_out", pictureUrl: pre },
  // { header: "Food&Drink", detail: "detail_food_and_drink", pictureUrl: pre },
  // { header: "My Planner", detail: "detail_my_planner", pictureUrl: pre },
]

const LandingPage = (props: Props) => {
  const { t, i18n } = useTranslation();

  return (
    <>
      <Navbar params={{ lng: i18n.language, isGuestService: true }} />
      <div className='page'>
        <div className='main'>
          <div>
            <img className="banner-img" src={Banner} alt="" />
          </div>
          <div id="Guest">
            <h1 className="title fw-bold">{t('guest_services')}</h1>
          </div>
          <div className='content row'>
            {data.map((item, index) => (
              index % 2 === 0 ? (
                <>
                  <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                    <div className="d-flex flex-xl-row flex-lg-row flex-md-row flex-sm-column flex-column">
                      <div className='col-item d-flex justify-content-center'>
                        <img src={item.pictureUrl} alt="" width={250} />
                      </div>
                      <div className='col-item d-flex align-items-md-center align-items-xl-start justify-content-center'>
                        <div className='d-flex flex-column align-items-lg-start justify-content-lg-start align-items-md-center justify-content-md-start align-items-center pt-xl-5 pt-md-2 ps-xl-5 ps-lg-3 ps-md-3 ps-md-2'>
                          <h1 className="text-title-details text-xl-start text-lg-start">{item.header}</h1>
                          <p className="text-details text-xl-start text-lg-start">{t(item.detail)}</p>
                        </div>
                      </div>
                    </div>
                  </div>

                </>
              ) : (
                <>
                  <div className='col-xl-6 col-lg-6 col-md-12 col-sm-12'>
                    <div className="d-flex flex-xl-row flex-lg-row flex-md-row flex-sm-column-reverse flex-column-reverse justify-content-end">
                      <div className='col-item w-100 d-flex align-items-md-center align-items-xl-end justify-content-start'>
                        <div className='d-flex flex-column align-items-lg-start justify-content-lg-start align-items-md-center justify-content-md-start align-items-center pb-xl-5 pb-md-2 pe-xl-5 pe-xl-3 pe-md-3 pe-md-2'>
                          <h1 className="text-title-details text-xl-start text-lg-start">{item.header}</h1>
                          <p className="text-details text-xl-start text-lg-start">{t(item.detail)}</p>
                        </div>
                      </div>
                      <div className='col-item d-flex justify-content-center'>
                        <img src={item.pictureUrl} alt="" width={250} />
                      </div>
                    </div>
                  </div>

                </>
              )
            ))}
          </div>
        </div>

      </div>
      <Footer />
    </>

  )
}

export default LandingPage