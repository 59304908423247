import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import Navbar from '../component/Navbar';

type Props = {}

const TermCondition = (props: Props) => {
	const { t, i18n } = useTranslation();
	useEffect(() => {
		// เมื่อหน้าจอโหลดใหม่ ให้เลื่อนไปที่ด้านบนสุด
		window.scrollTo(0, 0);
	}, []);
	return (
		<>
			<Navbar params={{ lng: i18n.language, isGuestService: false }} />
			<div className="page">
				<div className='d-flex flex-column align-items-center justify-content-top'>
					<h1 className='fw-bold term-title'>{t('terms_and_condition')}</h1>
				</div>
				<div className="term-content d-flex flex-column h-100 align-items-start justify-content-top">
					<p className='term-text text-lg text-start'><b>{t('header_terms')}</b></p>
					<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_header1')}</span></p>
					<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_header2')}</span></p>
					<p className='term-text text-lg text-start'><b>{t('header_book_resturant')}</b></p>
					<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_book_resturant1')}</span></p>
					<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_book_resturant2')}</span></p>
					<p className='term-text text-lg text-start'><b>{t('header_deposit')}</b></p>
					<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_deposi')}</span></p>
					<p className='term-text text-lg text-start'><b>{t('header_payment_currency')}</b></p>
					<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_payment_currency')}</span></p>
					<p className='term-text text-lg text-start'><b>{t('header_relocation')}</b></p>
					<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_relocation1')}</span></p>
					{i18n.language === 'en' && (
						<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_relocation2')}</span></p>
					)}
					<p className='term-text text-lg text-start'><b>{t('header_disclaimers')}</b></p>
					<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_disclaimers1')}</span></p>
					<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_disclaimers2')}</span></p>
					<p className='term-text text-lg text-start'><span><b>{t('hearder_limitation_of_liability')}</b></span></p>
					<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('hearder_limitation_of_liability')}</span></p>
					<p className='term-text text-lg text-start'><b>{t('header_links_to_third_parties')}</b></p>
					<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_links_to_third_parties')}</span></p>
					<p className='term-text text-lg text-start'><b>{t('header_governing_law_and_jurisdiction')}</b></p>
					<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail__governing_law_and_jurisdiction1')}</span></p>
					<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail__governing_law_and_jurisdiction2')}</span></p>
				</div>
			</div>

		</>
	)
}

export default TermCondition