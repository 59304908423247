import React, { useEffect } from 'react'
import { useTranslation } from 'react-i18next';
import Navbar from '../component/Navbar';

type Props = {}

const PrivacyPolicy = (props: Props) => {
    const { t, i18n } = useTranslation();
    useEffect(() => {
        // เมื่อหน้าจอโหลดใหม่ ให้เลื่อนไปที่ด้านบนสุด
        window.scrollTo(0, 0);
    }, []);
    const documentUrl = process.env.PUBLIC_URL + '/doc/D14.docx';
    return (
        <>
            <Navbar params={{ lng: i18n.language, isGuestService: false }} />
            <div className='page'>
                <div className='flex flex-col items-center justify-top'>
                    <h1 className='fw-bold term-title'>{t('header_privacy_policy')}</h1>
                </div>
                <div className="term-content d-flex flex-column h-100 align-items-start justify-content-top">
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_privacy_policy')}</span></p>
                    <p className='term-text text-lg text-start'><b>{t('header_what_is_pernal')}</b></p>
                    <ol className='pl-12 py-1 ml-10' style={{ listStyleType: 'disc' }}>
                        <li>
                            <p className='term-text text-start'><span>{t('dot_what_is_pernal_data1')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('dot_what_is_pernal_data2')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('dot_what_is_pernal_data3')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('dot_what_is_pernal_data4')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('dot_what_is_pernal_data5')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('dot_what_is_pernal_data6')}</span></p>
                        </li>
                    </ol>
                    <p className='term-text text-lg text-start'><b>{t('header_we_will_collect')}</b></p>
                    <p className='term-text text-start'><span>{t('detail_head_we_will_collect')}</span></p>
                    <ol className='pl-12 py-1 ml-10' style={{ listStyleType: 'disc' }}>
                        <li>
                            <p className='term-text text-start'><span>{t('dot_we_will_collect_data1')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('dot_we_will_collect_data2')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('dot_we_will_collect_data3')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('dot_we_will_collect_data4')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('dot_we_will_collect_data5')}</span></p>
                        </li>
                    </ol>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('sub_head_we_will_collect')}</span></p>
                    <ol className='pl-12 py-1 ml-10' style={{ listStyleType: 'decimal', paddingLeft: '60px' }}>
                        <li>
                            <p className='term-text text-start'><span>{t('sub_head_we_will_collect_data1')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('sub_head_we_will_collect_data2')}</span></p>
                        </li>
                    </ol>
                    <p className='term-text text-lg text-start'><b>{t('header_purpose_of_data')}</b></p>
                    <ol className='pl-12 py-1 ml-10' style={{ listStyleType: 'decimal', paddingLeft: '60px' }}>
                        <li>
                            <p className='term-text text-start'><span>{t('sub_purpose_of_data1')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('sub_purpose_of_data2')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('sub_purpose_of_data3')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('sub_purpose_of_data4')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('sub_purpose_of_data5')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('sub_purpose_of_data6')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('sub_purpose_of_data7')}</span></p>
                        </li>
                    </ol>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_purpose_of_data1')}</span></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_purpose_of_data2')}</span></p>
                    <p className='term-text text-lg text-start'><b>{t('header_processing_of_personal_data')}</b></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_processing_of_personal_data')}</span></p>
                    <ol className='pl-12 py-1 ml-10' style={{ listStyleType: 'disc' }}>
                        <li>
                            <p className='term-text text-start'><span>{t('dot_processing_of_personal_data1')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('dot_processing_of_personal_data2')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('dot_processing_of_personal_data3')}</span></p>
                        </li>
                    </ol>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('sub_detail_processing_of_personal_data1')}</span></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('sub_detail_processing_of_personal_data2')}</span></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<b>{t('google_privacy_text')}&nbsp;&nbsp;</b><a href="">{t('google_privacy_url')}</a></span></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<b>{t('Azure_privacy_text')}&nbsp;&nbsp;</b><a href="">{t('Azure_privacy_url')}</a></span></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<b>{t('zoho_privacy_text')}&nbsp;&nbsp;</b><a href="">{t('zoho_privacy_url')}</a></span></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<b>{t('service_desk_privacy_text')}&nbsp;&nbsp;</b><a href="">{t('service_desk_privacy_url')}</a></span></p>
                    <p className='term-text text-lg text-start'><b>{t('header_detail_retention')}</b></p>
                    <p className='term-text text-start'><span>{t('sub_detail_retention')}</span></p>
                    <ol className='pl-12 py-1 ml-10' style={{ listStyleType: 'decimal', paddingLeft: '60px' }}>
                        <li>
                            <p className='term-text text-start'><span>{t('detail_retention_data1')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('detail_retention_data2')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('detail_retention_data3')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('detail_retention_data4')}</span></p>
                        </li>
                    </ol>
                    <p className='term-text text-lg text-start'><b>{t('header_personal_data_retention')}</b></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_personal_data_retention1')}</span></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_personal_data_retention2')}</span></p>
                    <p className='term-text text-lg text-start'><b>{t('head_your_rights')}</b></p>
                    <ol className='pl-12 py-1 ml-10' style={{ listStyleType: 'decimal', paddingLeft: '60px' }}>
                        <li>
                            <p className='term-text text-start'><span>{t('your_rights_data1')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('your_rights_data2')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('your_rights_data3')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('your_rights_data4')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('your_rights_data5')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('your_rights_data6')}</span></p>
                        </li>
                        <li>
                            <p className='term-text text-start'><span>{t('your_rights_data7')}</span></p>
                        </li>
                    </ol>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_your_rights_data1')}&nbsp;</span>
                        <a href={documentUrl} download="D14.docx">
                            {t('download_document')}
                        </a>
                    </p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_your_rights_data2')}</span></p>
                    <p className='term-text text-lg text-start'><b>{t('header_contact_us')}</b></p>
                    <p className='term-text text-start'><span>{t('sub_header_contact_us')}</span></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_contact_us1')}</span></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<b>{t('detail_head_contact_us2')}</b>&nbsp;{t('detail_contact_us2')}</span></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<b>{t('detail_head_contact_us3')}</b>&nbsp;{t('detail_contact_us3')}</span></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<b>{t('detail_head_contact_us4')}</b>&nbsp;{t('detail_contact_us4')}</span></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<b>{t('detail_head_contact_us5')}</b>&nbsp;{t('detail_contact_us5')}</span></p>
                    <p className='term-text text-lg text-start'><b>{t('header_regulatory_agency')}</b></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_regulatory_agency1')}</span></p>
                    {i18n.language === 'en' ? (<p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_regulatory_agency2')}</span></p>) : (<></>)}
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('sub_detail_regulatory_agency')}</span></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<b>{t('head_email_regulatory_agency')}</b>&nbsp;{t('email_regulatory_agency')}</span></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;<b>{t('head_tel_regulatory_agency')}</b>&nbsp;{t('tel_regulatory_agency')}</span></p>
                    <p className='term-text text-lg text-start'><b>{t('header_changes_to_personal')}</b></p>
                    <p className='term-text text-start'><span>&nbsp; &nbsp; &nbsp; &nbsp; &nbsp;{t('detail_changes_to_personal')}</span></p>
                </div>
            </div>

        </>
    )
}

export default PrivacyPolicy