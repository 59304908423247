import React from 'react'
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

type Props = {}

const Footer = (props: Props) => {
    const { t, i18n } = useTranslation();
    const navigate = useNavigate();
    return (
        <div className='ps-sm-0 ps-xl-5 position-absolute d-flex justify-content-start align-items-center' style={{ height: '64px', width: '100%', background: "linear-gradient(179deg, #520A7A 8.3%, #820228 91.19%)" }}>
            <p className='text-footer my-0' onClick={() => navigate('/terms')}>{t('terms_and_condition')}</p>
            <p className='text-footer my-0' onClick={() => navigate('/privacy-policy')}>{t('pivacy_policy')}</p>
        </div>
    )
}

export default Footer