import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import th from '../src/assets/locales/th/translation.json';
import en from '../src/assets/locales/en/translation.json';

i18n
    .use(initReactI18next)
    .init({
        resources: {
            en: { translation: en }, // Wrap en in an object with 'translation' key
            th: { translation: th }, // Wrap th in an object with 'translation' key
        },
        lng: 'th', // Default language
        fallbackLng: 'en', // Fallback language if translation is missing
        interpolation: {
            escapeValue: false,
        },
    });

export default i18n;
